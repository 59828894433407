$width-ui : 1920;
$bg-md : 767.98px;

/* #Jumbotron
================================================== */
.jombotron {
  background: url("/assets/img/bg_header.png") no-repeat;
  background-size: cover;
  padding-top: 200px;
  height: 100vh;

  h2 {
    font-size: calc((29 / $width-ui) * 100vw);
    line-height: 150%;
    letter-spacing: 0.55em;
    color: #FFFFFF;
  }

  img {
    width: calc((890 / $width-ui) * 100vw);
  }
}

@media (max-width: $bg-md) {
  .jombotron {
    padding-top: 0;
    h2 {
      font-size: calc((45 / $width-ui) * 100vw);
      letter-spacing: 0.45em;
    }
    img {
      width: calc((1200 / $width-ui) * 100vw);
    }
  }

}


/* #Services
================================================== */
.services {
  background-color: #e5e55a;
  .ct-left {
    h3{
      font-weight: 600;
      font-size: calc((33 / $width-ui) * 100vw);
      line-height: 120%;
    }
    p{
      font-size: calc((19 / $width-ui) * 100vw);
      line-height: 120%;
    }
    img {
      width: 90%;
    }
  }
  .ct-right {
    img {
      width: 100%;
    }
  }
}

@media (max-width: $bg-md) {
  .services {
    .ct-left {
      h3{
        font-size: 18px;
      }
      p{
        font-size: 14px;
      }
      img {
        width: 100%;
      }
    }
    .ct-right {
      img {
        width: 100%;
      }
    }
  }
}

/* #HOW IT WORKS AND NEWS
================================================== */
.works-news-container {
  background-color: #fa7947;
  h3{
    font-weight: 700;
    font-size: calc((32 / $width-ui) * 100vw);
    color: #FFFFFF;
    padding: calc((90 / $width-ui) * 100vw) 0;
  }
  .card-item {
    cursor: pointer;
    img{
      width: 100%;
    }
    .label-arrow {
      width: calc((436 / $width-ui) * 100vw);
      padding: calc((34 / $width-ui) * 100vw) calc((31 / $width-ui) * 100vw);
      font-weight: 700;
      font-size: calc((35 / $width-ui) * 100vw);
      color: #E5E55A;
      background: url("/assets/img/btn_blue_point.png") no-repeat;
      background-size: calc((436 / $width-ui) * 100vw) auto;
      bottom: calc((80 / $width-ui) * 100vw);
    }
    &:hover {
      img {
        filter: brightness(50%);
        transition: filter .5s;
      }
    }
  }
}

#videoModal {
  .modal-content {
    background-color: transparent;
    border: none;
  }
  .modal-video {
    .close-x {
      font-weight: 600;
      font-size: 30px;
      color: #26a3bb;
      cursor: pointer;
      background-color: #FFFFFF;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

.detail-news {
  .ornament {
    width: calc((212 / $width-ui) * 100vw);
    right: 0;
    img{
      width: 100%;
    }
    .close-x {
      padding: 10px;
      font-weight: 600;
      font-size: calc((50 / $width-ui) * 100vw);
      color: #FFFFFF;
      cursor: pointer;
    }
  }
  h2 {
    font-weight: 600;
    font-size: calc((50 / $width-ui) * 100vw);
    line-height: 120%;
  }
  .text-news {
    background-color: #26a3bb;
    color: #FFFFFF;
    p {
      font-size: calc((22 / $width-ui) * 100vw);
      line-height: 120%;
      &.source {
        font-size: calc((16 / $width-ui) * 100vw);
        font-weight: 400;
        a {
          font-weight: 700;
        }
      }
    }
  }
}

@media (max-width: $bg-md) {
  .works-news-container {
    h3{
      font-size: 18px;
      padding: 30px 0;
    }
    .card-item {
      .label-arrow {
        width: 200px;
        padding: 15px;
        font-size: 14px;
        background-size: 200px auto;
        bottom: 80px;
      }
    }
  }

  #videoModal {
    .modal-video {
      .close-x {
        font-size: 20px;
        width: 30px;
        height: 30px;
      }
    }
  }
  
  .detail-news {
    .ornament {
      .close-x {
        padding: 10px;
        font-size: calc((60 / $width-ui) * 100vw);
      }
    }
    h2 {
      font-size: 20px;
    }
    .text-news {
      p {
        font-size: 14px;
        &.source {
          font-size: 12px;
        }
      }
    }
  }
}

/* #LOCATIONS
================================================== */
.location-container {
  background-color: #7c3283;
  color: #FFFFFF;
  h3{
    font-weight: 700;
    font-size: calc((32 / $width-ui) * 100vw);
    color: #FFFFFF;
    padding: calc((90 / $width-ui) * 100vw) 0;
  }
  .img-map {
    width: 90%;
  }
  p, h4{
    font-size: calc((32 / $width-ui) * 100vw);
    line-height: 120%;
  }
  p.desc{
    font-weight: 600;
    width: 68%;
  }
  .partner {
    width: 65%;
    img {
      height: calc((80 / $width-ui) * 100vw);
    }
  }
  .btn-location {
    width: fit-content;
    margin-top: calc((120 / $width-ui) * 100vw);
    border-radius: 50%;
    img{
      width: calc((488 / $width-ui) * 100vw);
    }
    &:hover {
      box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
    }
  }
}

@media (max-width: $bg-md) {
  .location-container {
    h3{
      font-size: 18px;
      padding: 30px 0;
    }
    p, h4{
      font-size: 14px;
    }
    p.desc{
      width: 75%;
    }
    .partner {
      width: 100%;
      img {
        height: 30px;
      }
    }
    .btn-location {
      img{
        width: 300px;
      }
    }
  }
}

/* #CLIENT
================================================== */
.brands-container {
  background-color: #ffffff;
  h3{
    font-weight: 700;
    font-size: calc((32 / $width-ui) * 100vw);
    padding-top: calc((150 / $width-ui) * 100vw);
    padding-bottom: 10px;
  }
}

@media (max-width: $bg-md) {
  .brands-container {
    h3{
      font-size: 18px;
      padding-top: 30px;
      padding-bottom: 15px;
    }
  }
}

/* #CONTACT
================================================== */
.contact-container {
  color: #FFFFFF;
  background: url("/assets/img/bg_pattern.png") no-repeat;
  background-size: cover;
  h3{
    font-weight: 700;
    font-size: calc((32 / $width-ui) * 100vw);
    padding-top: calc((120 / $width-ui) * 100vw);
    padding-bottom: 30px;
  }
  .mapouter{
    position:relative;
    text-align:right;
    width:100%;
    height:400px;
  }
  .gmap_canvas {
    overflow:hidden;
    background:none!important;
    width:100%;
    height:400px;
  }
  .gmap_iframe {
    height:400px!important;
  }
  .contact-item {
    font-size: calc((19 / $width-ui) * 100vw);
    line-height: 139.5%;
    padding: calc((40 / $width-ui) * 100vw) 0;
    border-bottom: 1.5px solid #FFFFFF;
    h4{
      font-weight: 700;
      color: #E5E55A;
    }
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border: none;
    }
  }
}

@media (max-width: $bg-md) {
  .contact-container {
    h3{
      font-size: 18px;
      padding: 30px 0;
    }
    .contact-item {
      font-size: 14px;
      padding: 20px 0;
    }
  }
}