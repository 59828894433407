@font-face {
  font-family: 'Sora';
  src: url('./assets/font/Sora-ExtraBold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Sora';
  src: url('./assets/font/Sora-Bold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Sora';
  src: url('./assets/font/Sora-Regular.otf') format('opentype');
}

$width-ui : 1920;
$bg-md : 767.98px;

html {
  scroll-behavior: smooth;
}

body, h1, h2, h3, h4, h5, h6, a, p, ul, ol {
  font-family: 'Sora', sans-serif;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

@mixin make-container($padding-x: calc((70 / $width-ui) * 100vw)) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}

.ui-container {
  @include make-container();
}

.bg-black {
  background-color: #000000;
}

.btn-lang {
  font-weight: 600;
  font-size: calc((20 / $width-ui) * 100vw);
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  width: calc((41 / $width-ui) * 100vw);
  height: calc((41 / $width-ui) * 100vw);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  cursor: pointer;

  &.active, &:hover{
    color: #E5E55A;
    border-color: #E5E55A;
  }
}

.ornament {
  width: calc((212 / $width-ui) * 100vw);
  right: calc((100 / $width-ui) * 100vw);
  img{
    width: 100%;
  }
}

@media (max-width: $bg-md) {
  .btn-lang {
    font-size: 12px;
    width: 26px;
    height: 26px;
  }

  .ornament {
    width: 60px;
    right: calc((100 / $width-ui) * 100vw);
    img{
      width: 100%;
    }
  }
}

@import './assets/styles/navbar.scss';
@import './assets/styles/footer.scss';
@import './assets/styles/home.scss';
@import './assets/styles/location.scss';
@import './assets/styles/page404.scss';