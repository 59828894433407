$width-ui: 1920;
$bg-md: 767.98px;

/* #Jumbotron
================================================== */
.location-jombotron {
  background: url('/assets/img/bg_header.png') no-repeat;
  background-size: cover;
  height: calc((500 / $width-ui) * 100vw);
}

@media (max-width: $bg-md) {
  .location-jombotron {
    height: 150px;
  }
}

/* #Detail Location
================================================== */
.location-detail {
  background-color: #7c3283;
  color: #ffffff;
  .ct-left {
    font-weight: 600;
    line-height: 120%;
    font-size: calc((29 / $width-ui) * 100vw);
    padding-top: calc((150 / $width-ui) * 100vw);
    padding-bottom: calc((50 / $width-ui) * 100vw);
    h3 {
      font-weight: 700;
      font-size: calc((32 / $width-ui) * 100vw);
    }
  }
  .ct-right {
    img {
      width: calc((664 / $width-ui) * 100vw);
      margin-bottom: -5px;
    }
  }
}

@media (max-width: $bg-md) {
  .location-detail {
    .ct-left {
      font-weight: 400;
      font-size: 14px;
      padding-top: 20px;
      padding-bottom: 20px;
      h3 {
        font-size: 18px;
      }
    }
    .ct-right {
      img {
        width: 200px;
      }
    }
  }
}

/* #Table Location
================================================== */
.location-table {
  background-color: #26a3bb;
  color: #ffffff;
  .table-slider-wrap {
    padding-top: calc((200 / $width-ui) * 100vw);
    padding-bottom: calc((200 / $width-ui) * 100vw);
    z-index: 2;
    position: relative;
  }
  .title-table {
    .logo-table {
      height: auto;
      &:first-child {
        width: calc((256 / $width-ui) * 100vw);
      }
      &:last-child {
        width: calc((286 / $width-ui) * 100vw);
      }
    }
  }
  .table-slide-item {
    width: calc((1328 / $width-ui) * 100vw);
    .content-table {
      width: 100%;
    }
  }
  p {
    font-size: calc((20 / $width-ui) * 100vw);
    b {
      font-weight: 600;
    }
    .star {
      font-weight: 200;
    }
    &.note {
      font-size: calc((14 / $width-ui) * 100vw);
    }
  }
  #tableCarousel {
    button img {
      width: calc((28 / $width-ui) * 100vw);
    }

    button.carousel-control-prev img {
      rotate: 180deg;
    }
  }
}

@media (max-width: $bg-md) {
  .location-table {
    .table-slider-wrap {
      padding: 40px 0;
    }
    .title-table {
      .logo-table {
        &:first-child,
        &:last-child {
          width: 100px;
        }
      }
    }
    .table-slide-item {
      width: 93%;
    }
    p {
      font-size: 12px;
      &.note {
        font-size: 10px;
      }
    }
    #tableCarousel {
      button img {
        width: 15px;
      }
    }
  }
}
