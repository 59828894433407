$width-ui : 1920;
$bg-md : 767.98px;

/* #Footer
================================================== */
footer {
  background-color: #000000;
  color: #ffffff;
  padding: calc((70 / $width-ui) * 100vw) 0;
  .nav-footer {
    width: 60%;
    li {
      font-size: calc((25 / $width-ui) * 100vw);
      a {
        color: #ffffff;
      }
      &.active a, &:hover a{
        color: #E5E55A;
      }
    }
  }
  .social {
    a {
      border-radius: 50%;
      img {
        width: calc((55 / $width-ui) * 100vw);
      }
    }
  }
  .wir-unit {
    img {
      width: calc((232 / $width-ui) * 100vw);
    }
  }
  p.copyright {
    font-size: calc((18 / $width-ui) * 100vw);
  }
}

@media (max-width: $bg-md) {
  footer {
    padding: 50px 0;
    .nav-footer {
      width: 90%;
      li {
        font-size: 11px;
        padding-bottom: 20PX;
      }
    }
    .social {
      a {
        img {
          width: 24px;
        }
      }
    }
    .wir-unit {
      img {
        width: 140px;
      }
    }
    p.copyright {
      font-size: 10px;
    }
  }
}