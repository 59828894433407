
$width-ui : 1920;
$bg-md : 767.98px;

/* #Navigation
================================================== */

.start-header {
	opacity: 1;
	transform: translateY(0);
	padding: 20px 0;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	.navbar-nav {
		margin-top: calc((180 / $width-ui) * 100vw);
	}
}
.start-header.scroll-on {
	padding: 20px 0;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	.navbar-brand {
		img {
			height: calc((50 / $width-ui) * 100vw);
			-webkit-transition: all 0.3s ease-out;
			transition: all 0.3s ease-out;
		}
	}
	.navbar-nav {
		margin-top: 0;
	}
}
.navigation-wrap {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
.navbar {
	padding: 0;
}
.navbar-brand {
	img {
		height: calc((89 / $width-ui) * 100vw);
		width: auto;
		display: block;
		-webkit-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
	}
}
.navbar-toggler {
	float: right;
	border: none;
	padding-right: 0;
	&:active {
		outline: none;
	}
	&:focus {
		outline: none;
		box-shadow: none;
	}
}
.navbar-toggler-icon {
	width: 24px;
	height: 17px;
	background-image: none;
	position: relative;
	border-bottom: 1px solid #fff;
	transition: all 300ms linear;
	&:after {
		width: 24px;
		position: absolute;
		height: 1px;
		background-color: #fff;
		top: 0;
		left: 0;
		content: '';
		z-index: 2;
		transition: all 300ms linear;
		top: 8px;
	}
	&:before {
		width: 24px;
		position: absolute;
		height: 1px;
		background-color: #fff;
		top: 0;
		left: 0;
		content: '';
		z-index: 2;
		transition: all 300ms linear;
	}
}
.navbar-toggler[aria-expanded="true"] {
	.navbar-toggler-icon {
		&:after {
			transform: rotate(45deg);
		}
		&:before {
			transform: translateY(8px) rotate(-45deg);
		}
		border-color: transparent;
	}
}
.nav-link {
  font-family: 'Sora', sans-serif;
	color: #ffffff !important;
	font-weight: 500;
	transition: all 200ms linear;
	position: relative;
	padding: 5px 0 !important;
	display: inline-block;
}
.nav-item {
	&:hover {
		.nav-link {
			color: #E5E55A !important;
		}
	}
	position: relative;
	transition: all 200ms linear;
}
.nav-item.active {
	.nav-link {
		color: #E5E55A !important;
	}
	&:hover {
		&:after {
			opacity: 0;
		}
	}
}

@media (max-width: $bg-md) {
	.start-header {
		padding: 15px 0;
		.navbar-nav {
			margin-top: 0;
		}
	}
	.start-header.scroll-on {
		padding: 15px 0;
		.navbar-brand {
			img {
				height: 30px;
			}
		}
	}
	.navbar-brand {
		img {
			height: 30px;
		}
	}
	.navbar-collapse.menu{
		height: 0;
		transition: all 300ms linear;
		.navbar-nav {
			text-align: center;
			margin-top: 10vh !important;
		}
		.nav-link {
			padding: 20px 0 !important;
			font-size: 20px;
			opacity: 0;
		}
		&.show {
			height: 100vh;
			.nav-link {
				opacity: 1;
			}
		}
	}
}