.not-found-wrapper {
  background: url("/assets/img/bg_header.png") no-repeat;
  background-size: cover;
  padding-top: 40vh;
  height: 100vh;
  color: #ffffff;
  h1 {
    font-size: 10vw;
    color: #e5e55a;
  }
  h2 {
    font-size: 2vw;
  }
  a {
    font-weight: 600;
    font-size: 1.5vw;
    background-color: #7c3283;
    padding: 15px 20px;
    display: block;
    width: fit-content;
    border-radius: 25px;
    transition: background-color .5s;
    &:hover {
      background-color: #25a5bb;
    }
  }
}

@media (max-width: $bg-md) {
  .not-found-wrapper {
    h1 {
      font-size: 25vw;
    }
    h2 {
      font-size: 5vw;
    }
    a {
      font-size: 3vw;
    }
  }
}